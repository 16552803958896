<template>
    <div>
        <base-header type="gradient-hyb-cyan" class="pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <address-table
                      :addresses="addresses"
                      @paginate="handlePaginate"
                      @import="fetchAddresses"
                    ></address-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddressTable from './Tables/AddressTable'
import { mapState } from 'vuex'

export default {
  name: 'StreetDirectory',
  components: {
    AddressTable
  },
  computed: {
    ...mapState('address', [
      'addresses'
    ])
  },
  mounted () {
    this.fetchAddresses()
  },
  methods: {
    fetchAddresses () {
      this.$store.dispatch('address/fetchAddresses')
    },
    handlePaginate (pageNum) {
      this.$store.dispatch('address/setPageNum', pageNum)
      this.$store.dispatch('address/fetchAddresses')
    }
  }
};
</script>

<style></style>
