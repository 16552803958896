<template>
    <div>
        <base-header type="gradient-hyb-cyan" class="pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <orders-table
                      :title="tableTitle" 
                      :status="status"
                      :orders="orders"
                      @paginate="handlePaginate"
                      @search="handleSearch"
                    ></orders-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OrdersTable from './Tables/OrdersTable'
import { mapState } from 'vuex'

export default {
  name: 'Order',
  components: {
    OrdersTable
  },
  computed: {
    ...mapState('order', [
      'orders'
    ]),
    status () {
      const { status } = this.$route.params
      return status
    },
    tableTitle () {
      let title = 'Orders'
      switch (this.status) {
        case 'offene':
          title = 'Offene Briefe'
          break
        case 'freigegebene':
          title = 'Freigegebene Briefe'
          break
        case 'fertige':
          title = 'Fertige Briefe'
          break
        case 'abgerechnete':
          title = 'Abgerechnete Briefe'
          break
      }
      return title
    },
    orderQuery () {
      const params = {
        query: {}
      }
      if (this.status === 'freigegebene') {
        params.query.status = 'submitted',
        params.query.granted = true
      } else if (this.status === 'offene') {
        params.query.status = 'submitted',
        params.query.granted = false
      } else if (this.status === 'fertige') {
        params.query.status = 'printed'
      } else if (this.status === 'abgerechnete') {
        params.query.status = 'billed'
      }
      return params
    }
  },
  watch: {
    status() {
      this.resetOrders()
      this.fetchOrders()
    }
  },
  mounted () {
    if (!this.status || !['offene', 'freigegebene', 'fertige', 'abgerechnete'].includes(this.status)) {
      this.$router.replace({ name: 'order', params: { status: 'offene' }})
    }
    this.resetOrders()
    this.fetchOrders()
  },
  methods: {
    fetchOrders () {
      this.$store.dispatch('order/fetchOrders', this.orderQuery)
    },
    resetOrders () {
      this.$store.dispatch('order/resetOrders')
    },
    handlePaginate (pageNum) {
      this.$store.dispatch('order/setPageNum', pageNum)
      this.$store.dispatch('order/fetchOrders', this.orderQuery)
    },
    handleSearch (term) {
      this.$store.dispatch('order/fetchOrders', {
        ...this.orderQuery,
        search: term
      })
    }
  }
};
</script>

<style></style>
