<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col d-flex align-items-center text-right">
          <base-input
            class="input-group-alternative mb-0"
            placeholder="Search"
            addon-left-icon="fa fa-search"
            :value="searchTerm"
            @input="debounceSearch"
          >
          </base-input>
          <div v-if="status === 'offene'" class="ml-2">
            <base-button v-if="selectedOrdersCount > 0" type="primary" size="sm" @click="handleGrantSelected">Grant selected</base-button>
            <base-dropdown class="dropdown" position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>
              <template>
                <a v-if="!showMultiple" class="dropdown-item" href="#" @click="setMultiple(true)">Edit</a>
                <a v-else class="dropdown-item" href="#" @click="setMultiple(false)">Close Edit</a>
              </template>
            </base-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table v-if="orders.data" class="table align-items-center table-flush" :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'" tbody-classes="list" :data="orders.data.docs">
        <template slot="columns">
          <th v-if="showMultiple">
            <base-checkbox v-model="checkAll" />
          </th>
          <th>Bearbeiter</th>
          <th v-if="showStatus">Status</th>
          <th>Datum</th>
          <th>Zeit</th>
          <th>Stichwort</th>
          <th>Farbe</th>
          <th>Druckseite</th>
          <th>Seitenzahl</th>
          <th>Brief</th>
          <th v-if="showOnOrderGranted || showOnOrderBilled">UPOC</th>
          <th v-if="showOnOrderGranted">Freigabe</th>
          <th v-if="showOnOrderBilled">Abgerechnet mit</th>
          <th v-if="showActionColumn">Aktion</th>
        </template>

        <template slot-scope="{row}">
          <td v-if="showMultiple">
            <base-checkbox :checked="isSelected(row)" @input="toggleRowSelect(row)" />
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span v-if="row.userId" class="name mb-0 text-sm">{{row.userId.firstName}} {{row.userId.lastName}}</span>
              </div>
            </div>
          </th>
          <td v-if="showStatus">{{ getGrantStatus(row) }}</td>
          <td>{{ row.createdAt | formatDate }}</td>
          <td>{{ row.createdAt | formatTime }}</td>
          <td>{{ row.title }}</td>
          <td>{{ row.colormode }}</td>
          <td>
            <span v-if="row.duplex">2-seitig</span>
            <span v-else>1-seitig</span>
          </td>
          <td>{{ calculatePageCount(row) }} <span v-if="row.pagesplit && row.pagesplit > 0">(Serienbrief)</span></td>
          <td>
            <span v-if="row.letterType">{{ row.letterType.matchcode }}</span> <span v-if="row.briefType">{{ row.briefType }}</span>
          </td>
          <td v-if="showOnOrderGranted || showOnOrderBilled">{{ row.upocId ? row.upocId.code : '' }}</td>
          <td v-if="showOnOrderGranted">
            <span v-if="row.grantedAt">{{ row.grantedAt | formatDate }}</span> <span v-if="row.grantedAt">{{ row.grantedAt | formatTime }}</span>
          </td>
          <td v-if="showOnOrderBilled">
            <span v-if="row.billedAt">{{ row.billedAt | formatDate }}</span> <span v-if="row.billedAt">{{ row.billedAt | formatTime }}</span>
          </td>
          <td v-if="showActionColumn">
            <base-button v-if="status === 'offene' && showGrant" type="success" size="sm" @click="handleGrant(row)" :disabled="row.granted">
              {{ row.granted ? 'freigegeben' : 'freigeben' }}
            </base-button>
            <base-button v-if="status === 'offene'" type="danger" size="sm" @click="handleDelete(row)">löschen</base-button>
            <base-button type="warning" size="sm" :disabled="isDocumentDeleted(row)" @click="handleDownload(row)">download</base-button>
            <base-button v-if="status === 'freigegebene'" :disabled="isDeleteGrantDisabled(row)" type="danger" size="sm" @click="handleDelete(row)">löschen</base-button>
          </td>
        </template>
      </base-table>
    </div>
    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination v-if="orders.data" :pageCount="orders.data.totalPages" :value="orders.data.page" @input="(val) => $emit('paginate', val)"></base-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import debounce from 'debounce'

export default {
  name: 'orders-table',
  props: {
    type: {
      type: String
    },
    status: {
      type: String
    },
    title: String,
    orders: Object
  },
  data() {
    return {
      showMultiple: false,
      checkAll: false,
      selectedOrders: {},
      adminPrintCloseTime: null,
      searchTerm: ""
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    selectedOrdersCount () {
      return Object.keys(this.selectedOrders).length
    },
    showGrant () {
      return this.user && this.user.role !== 'order-provider'
    },
    showActionColumn () {
      return this.user && this.user.role !== 'admin'
    },
    showStatus () {
      return ['offene', 'freigegebene'].includes(this.status)
    },
    showOnOrderGranted () {
      return ['freigegebene', 'fertige'].includes(this.status)
    },
    showOnOrderBilled () {
      return ['abgerechnete'].includes(this.status)
    }
  },
  watch: {
    status() {
      this.showMultiple = false
      this.searchTerm = null
      this.checkAll = false
      this.selectedOrders = {}
      this.fetchAdminSettings()
    },
    checkAll (val) {
      if (val) {
        // check all
        let newSelected = {}
        for (const order of this.orders.data.docs) {
          newSelected[order._id] = true
        }
        this.selectedOrders = newSelected
      } else {
        // uncheck all
        this.selectedOrders = {}
      }
    },
    searchTerm (newVal) {
      this.$emit('search', newVal)
    }
  },
  mounted () {
    this.fetchAdminSettings()
  },
  methods: {
    isDeleteGrantDisabled (order) {
      if (this.adminPrintCloseTime && order.grantedAt) {
        const orderGrantedAt = moment(order.grantedAt);
        const orderCutoff = moment(moment(order.grantedAt).format('MM/DD/YYYY') + ' ' + this.adminPrintCloseTime);
        if(orderCutoff.isBefore(orderGrantedAt)) {
          orderCutoff.add(1, 'days');
        }
        return moment().isAfter(orderCutoff)
      }
      return false
    },
    debounceSearch: debounce (function (e) {
      this.searchTerm = e
    }, 400),
    getGrantStatus({ grantStatus, grantScheduledAt }) {
      let str = ''
      switch(grantStatus) {
        case 'PENDING':
          str = 'warten auf Freigabe';
          break
        case 'GRANTED':
          str = 'Freigabe';
          break;
        case 'AUTOGRANTED':
          str = 'autom. Freigabe';
          break
        case 'SCHEDULED':
          str = `autom. Freigabe am ${grantScheduledAt && moment(grantScheduledAt
          ).format('DD.MM.YYYY HH:mm')}`
          break
        default:
          str = grantStatus;
          break
      }
      return str
    },
    fetchAdminSettings () {
      if (this.status === 'freigegebene' && this.showActionColumn && this.user.parentAdmin) {
        this.$store.dispatch('user/fetchAdminSettings')
          .then((data) => {
            this.adminPrintCloseTime = data.printCloseTime || null
          })
      }
    },
    isDocumentDeleted (order) {
      return order.document && !!order.document.deletedAt
    },
    setMultiple (val) {
      this.showMultiple = val
      if (!val) {
        this.selectedOrders = {}
        this.checkAll = false
      }
    },
    calculatePageCount (row) {
      let count = 0
      if (row.document) {
        count += row.document.pageCount
      }
      if (row.attachments && row.attachments.length) {
        count += row.attachments.map(a => a.pageCount).reduce((a, b) => a + b, 0)
      }
      return count
    },
    toggleRowSelect (row) {
      const orderId = row._id

      if (orderId in this.selectedOrders) {
        // unselect order
        // eslint-disable-next-line no-unused-vars
        const { [orderId]: value, ...remaining } = this.selectedOrders
        this.selectedOrders = remaining
      } else {
        // select order
        this.selectedOrders = {
          ...this.selectedOrders,
          [orderId]: true
        }
      }
    },
    isSelected (row) {
      return row._id in this.selectedOrders
    },
    handleDownload (row) {
      row.isDownloading = true

      this.$store.dispatch('order/fetchOrderBundle', row._id)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
          const link = document.createElement('a')
          link.href = url

          const contentDisposition = response.headers['content-disposition']
          let fileName = `${row._id}.pdf`
          if (contentDisposition) {
            fileName = contentDisposition.split('filename=')[1].split(';')[0]
          }
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => {
          this.$notify({type: 'danger', message: 'Pdf is not available'})
        })
        .finally(() => {
          row.isDownloading = false
        })
    },
    handleDelete (row) {
      this.$confirm(
        {
          message: `Möchten Sie den Brief löschen?`,
          button: {
            no: 'Nein',
            yes: 'Ja'
          },
          callback: confirm => {
            if (confirm) {
              row.isDeleting = true
              this.$store.dispatch('order/deleteOrder', row._id)
                .then(() => {
                  this.$notify({type: 'success', message: 'Auftrag gelöscht'})
                })
                .catch((error) => {
                  if (error?.response?.data?.error?.description) {
                    this.$notify({type: 'danger', message: error.response.data.error.description})
                  }
                })
                .finally(() => {
                  row.isDeleting = false
                })
            }
          }
        }
      )
    },
    handleGrant (row) {
      this.$confirm(
        {
          message: `Möchten Sie den Brief freigeben?`,
          button: {
            no: 'Nein',
            yes: 'Ja'
          },
          callback: confirm => {
            if (confirm) {
              row.isGranting = true
              this.$store.dispatch('order/grantOrder', row)
                .then(() => {
                  this.$notify({type: 'success', message: 'Auftrag freigeben'})
                })
                .catch(({error}) => {
                  this.$notify({type: 'danger', message: error.http_message})
                })
                .finally(() => {
                  row.isGranting = false
                })
            }
          }
        }
      )
    },
    handleGrantSelected () {
      this.$confirm(
        {
          message: `Grant selected orders?`,
          button: {
            no: 'Nein',
            yes: 'Ja'
          },
          callback: confirm => {
            if (confirm) {
              Object.keys(this.selectedOrders).map((orderId) => {
                const order = this.orders.data.docs.find(doc => doc._id === orderId)
                if (order) {
                  this.$store.dispatch('order/grantOrder', order)
                    .then(() => {
                      this.$notify({type: 'success', message: 'Auftrag freigeben'})
                    })
                    .catch(({error}) => {
                      this.$notify({type: 'danger', message: error.http_message})
                    })
                }
              })
              this.setMultiple(false)
            }
          }
        }
      )
    }
  }
}
</script>

<style>
</style>
