<template>
    <div>
        <base-header type="gradient-hyb-cyan" class="pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <print-table
                      :orders="orders"
                      @paginate="handlePaginate"
                    ></print-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PrintTable from './Tables/PrintTable'
import { mapState } from 'vuex'

export default {
  name: 'Print',
  components: {
    PrintTable
  },
  computed: {
    ...mapState('order', [
      'orders'
    ])
  },
  mounted () {
    this.resetOrders()
    this.fetchOrders()
  },
  methods: {
    resetOrders () {
      this.$store.dispatch('order/resetOrders')
    },
    fetchOrders () {
      this.$store.dispatch('order/fetchOrders')
    },
    handlePaginate (pageNum) {
      this.$store.dispatch('order/setPageNum', pageNum)
      this.$store.dispatch('order/fetchOrders')
    }
  }
};
</script>

<style></style>
