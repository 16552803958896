<template>
    <div>
        <base-header type="gradient-hyb-cyan" class="pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <collection-table
                      :title="tableTitle" 
                      :orders="collections"
                    ></collection-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CollectionTable from './Tables/CollectionTable'
import { mapState } from 'vuex'

export default {
  name: 'Collection',
  components: {
    CollectionTable
  },
  computed: {
    ...mapState('order', [
      'collections'
    ]),
    tableTitle () {
      return 'Offene Sammelmappen'
    }
  },
  mounted () {
    this.fetchOrderCollections()
  },
  methods: {
    fetchOrderCollections () {
      this.$store.dispatch('order/fetchOrderCollections')
    }
  }
};
</script>

<style></style>
