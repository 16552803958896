<template>
  <base-input input-classes="form-control-alternative" :value="setting.value" @input="debounceEmit" />
</template>

<script>
import debounce from 'debounce'

export default {
  props: {
    setting: {
      type: Object
    }
  },
  methods: {
    debounceEmit: debounce(function (e) {
      this.setting.value = e
      this.$emit('update', this.setting)
    }, 500),
  }
}
</script>

<style>

</style>
