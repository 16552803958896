<template>
  <div>
    <div v-if="!loading">
      <div v-if="!letterTypes.length">
        Es wurde noch kein Briefrelais hinzugefügt
      </div>
      <template v-else> 
        <div class="row mt-2">
          <div class="col-2">
            <label class="form-control-label">Blätter* bis*</label>
          </div>
          <div class="col-2">
            <label class="form-control-label">Produkt*</label>
          </div>
          <div class="col-2">
            <label class="form-control-label">Matchcode*</label>
          </div>
        </div>
        <div v-for="letterType in letterTypes" :key="letterType.id" class="mb-1 row">
          <div class="col-2">
            <base-input
              input-classes="form-control-alternative"
              v-model="letterType.sheets"
            />
          </div>
          <div class="col-2">
            <base-input
              input-classes="form-control-alternative"
              v-model="letterType.product"
            />
          </div>
          <div class="col-2">
            <base-input
              input-classes="form-control-alternative"
              v-model="letterType.matchcode"
            />
          </div>
          <div class="col-auto d-flex align-items-center">
            <base-button type="" class="d-block" size="sm" @click="handleRemove(letterType)">x entfernen.</base-button>
          </div>
        </div>
      </template>
      <base-button v-if="letterTypes.length < 5" type="" class="d-block my-2" size="sm" @click="handleAdd">+ neues Buchstabenrelais hinzufügen</base-button>
      <base-button type="primary" class="col-auto mt-3" @click="handleSaveChanges">speichern</base-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'letter-types-editor',
  data() {
    return {
      letterTypes: [],
      loading: true
    }
  },
  computed: {
    ...mapState({
      userId: state => state.auth.user._id
    })
  },
  mounted () {
    this.fetchUserLetterTypes()
  },
  methods: {
    fetchUserLetterTypes () {
      this.loading = true

      this.$store.dispatch('user/fetchUser', this.userId)
        .then((data) => {
          this.letterTypes = data.letterTypes || []
        })
        .catch(() => {
          this.$notify({type: 'danger', message: 'Kann die Brieftypen des Benutzers nicht abrufen'})
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleAdd () {
      this.letterTypes = [
        ...this.letterTypes,
        {
          sheets: null,
          product: null,
          matchcode: null
        }
      ]
    },
    handleRemove (letterType) {
      this.letterTypes = this.letterTypes.filter(lt => lt !== letterType)
    },
    handleSaveChanges () {
      const payload = {
        userId: this.userId,
        body: {
          letterTypes: this.letterTypes.map(lt => ({
            ...lt,
            sheets: parseInt(lt.sheets)
          }))
        }
      }
      this.$store.dispatch('user/updateUser', payload)
        .then((data) => {
          this.user = data
          this.$notify({type: 'success', message: 'Gespeicherte Brieftypen'})
        })
        .catch((error) => {
          this.$notify({type: 'danger', message: error?.response?.data?.error?.description})
        })
    }
  }
}
</script>
