<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            Grouppen
          </h3>
        </div>
        <div v-if="allowCreateGroup" class="col text-right">
          <base-button type="primary" size="sm" @click="handleCreateGroup">Neue Gruppe</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table v-if="groups.data" class="table align-items-center table-flush" :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'" tbody-classes="list" :data="sortedGroups">
        <template slot="columns">
          <th>Gruppenname</th>
          <th>Kostenstelle</th>
          <th>Farbigkeit</th>
          <th>Druck</th>
          <th>Action</th>
        </template>
        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.name}}</span>
              </div>
            </div>
          </th>
          <td>{{ row.location }}</td>
          <td>
            <span v-if="row.isDefault">{{ getUserPrintSettings(row) && getUserPrintSettings(row).color }}</span>
            <span v-else>{{ row.printSettings && row.printSettings.color }}</span>
          </td>
          <td>
            <span v-if="row.isDefault">{{ getUserPrintSettings(row) && getUserPrintSettings(row).side }}</span>
            <span v-else>{{ row.printSettings && row.printSettings.side }}</span>
          </td>
          <td>
            <base-button v-if="!row.isDefault" type="success" size="sm" @click="handleEditUser(row)">Nutzer bearbeiten ({{ row.users.length }})</base-button>
            <base-button type="success" size="sm" @click="handleEdit(row)">Gruppe bearbeiten</base-button>
            <base-button v-if="!row.isDefault" type="danger" size="sm" @click="handleDelete(row)">Gruppe löschen</base-button>
          </td>
        </template>
      </base-table>
    </div>
    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination v-if="groups.data" :pageCount="groups.data.totalPages" :value="groups.data.page" @input="(val) => $emit('paginate', val)"></base-pagination>
    </div>
    <modal :show="groupModal.show">
      <card shadow type="secondary">
          <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                  <div class="col-8">
                      <h3 class="mb-0">
                        {{ groupModal.isEdit ? 'Gruppe Bearbeiten' : 'Neue Gruppe' }}</h3>
                  </div>
                  <div class="col-4 text-right">
                    <button type="button"
                            class="close"
                            @click="groupModal.show = false"
                            data-dismiss="modal"
                            aria-label="Close">
                      <span>×</span>
                    </button>
                  </div>
              </div>
          </div>
          <template>
              <form ref="groupForm" @submit.prevent>
                  <div class="">
                      <div class="row">
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Grouppename*"
                                          placeholder=""
                                          input-classes="form-control-alternative"
                                          v-model="model.name"
                                          :readonly="model.isDefault"
                              />
                          </div>
                          <div v-if="!model.isDefault" class="col-lg-6">
                              <base-input alternative=""
                                          label="Kostenstelle*"
                                          placeholder=""
                                          input-classes="form-control-alternative"
                                          v-model="model.location"
                              />
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-12">
                              <base-input label="Color*">
                                <select v-model="model.printSettings.color" class="form-control" required>
                                  <option v-for="(color, index) in colorOptions" :key="index">{{ color }}</option>
                                </select>
                              </base-input>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-12">
                              <base-input label="Side*">
                                <select v-model="model.printSettings.side" class="form-control" required>
                                  <option v-for="(side, index) in sideOptions" :key="index">{{ side }}</option>
                                </select>
                              </base-input>
                          </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col text-right">
                          <base-button type="light" @click="groupModal.show = false">Abbrechen</base-button>
                          <base-button type="primary" :disabled="isSubmitting" @click="handleSubmit">Bestätigen</base-button>
                        </div>
                      </div>
                      <p v-if="errMsg" class="mb-0 mt-3 text-danger text-right text-sm">{{ errMsg }}</p>
                  </div>
              </form>
          </template>
      </card>
    </modal>
    <modal :show="userModal.show">
      <card shadow type="secondary">
          <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                  <div class="col-8">
                      <h3 class="mb-0">Nutzer Bearbeiten</h3>
                  </div>
                  <div class="col-4 text-right">
                    <button type="button"
                            class="close"
                            @click="userModal.show = false"
                            data-dismiss="modal"
                            aria-label="Close">
                      <span>×</span>
                    </button>
                  </div>
              </div>
          </div>
          <template>
              <form ref="userForm" @submit.prevent>
                  <div class="">
                      <div class="row mb-2">
                          <div v-if="selectedGroup" class="col-lg-12">
                            <h5>Nutzer gruppieren</h5>
                            <div v-for="userId in selectedGroup.users" :key="userId" class="align-items-center d-flex justify-content-between mb-2">
                              <div>
                                  {{ orderProviderIdToDoc[userId].firstName }} {{ orderProviderIdToDoc[userId].lastName }}
                                  <small>{{ orderProviderIdToDoc[userId].email }}</small>
                              </div>
                              <base-button type="danger" size="sm" @click="handleRemoveUserFromGroup(userId)">löschen</base-button>
                            </div>
                            <span v-if="!selectedGroup.users || !selectedGroup.users.length">Noch keine Nutzer in dieser Gruppe</span>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-12">
                              <base-input label="Nutzer auswählen">
                                <select v-model="newUserId" class="form-control" required>
                                  <option v-for="(orderProvider, index) in orderProviderOptions" :key="index" :value="orderProvider._id">
                                    {{ orderProvider.firstName }} {{ orderProvider.lastName }}
                                  </option>
                                </select>
                                <base-button
                                  type="primary"
                                  class="mt-2"
                                  :disabled="!newUserId || isAddingUserToGroup"
                                  @click="handleAddUserToGroup"
                                >Nutzer hinzufügen</base-button>
                              </base-input>
                          </div>
                      </div>
                       <div class="row mb-2 mt-4">
                          <div v-if="selectedGroup" class="col-lg-12">
                            <h5>Supervisor gruppieren</h5>
                            <div v-for="userId in selectedGroup.supervisors" :key="userId" class="align-items-center d-flex justify-content-between mb-2">
                              <div>
                                  {{ orderProviderIdToDoc[userId].firstName }} {{ orderProviderIdToDoc[userId].lastName }}
                                  <small>{{ orderProviderIdToDoc[userId].email }}</small>
                              </div>
                              <base-button type="danger" size="sm" @click="handleRemoveSupervisorFromGroup(userId)">löschen</base-button>
                            </div>
                            <span v-if="!selectedGroup.supervisors || !selectedGroup.supervisors.length">Noch keine Supervisor in dieser Gruppe</span>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-12">
                              <base-input label="Supervisor auswählen">
                                <select v-model="newSupervisorId" class="form-control" required>
                                  <option v-for="(orderProvider, index) in supervisorOptions" :key="index" :value="orderProvider._id">
                                    {{ orderProvider.firstName }} {{ orderProvider.lastName }}
                                  </option>
                                </select>
                                <base-button
                                  type="primary"
                                  class="mt-2"
                                  :disabled="!newSupervisorId || isAddingSupervisorToGroup"
                                  @click="handleAddSupervisorToGroup"
                                >Supervisor hinzufügen</base-button>
                              </base-input>
                          </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col text-right">
                          <base-button type="light" @click="userModal.show = false">Abbrechen</base-button>
                        </div>
                      </div>
                      <p v-if="errMsg" class="mb-0 mt-3 text-danger text-right text-sm">{{ errMsg }}</p>
                  </div>
              </form>
          </template>
      </card>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'groups-table',
  props: {
    type: {
      type: String
    },
    title: String,
    groups: Object
  },
  data() {
    return {
      isSubmitting: false,
      isAddingUserToGroup: false,
      isAddingSupervisorToGroup: false,
      isRemovingUserFromGroup: {},
      isRemovingSupervisorFromGroup: {},
      errMsg: null,
      selectedGroup: null,
      groupModal: {
        show: false,
        isEdit: false
      },
      userModal: {
        show: false
      },
      newUserId: null,
      newSupervisorId: null,
      model: {
        name: null,
        location: null,
        printSettings: {
          color: null,
          side: null
        },
        isDefault: false
      },
      orderProviders: [],
      colorOptions: ['Farbe/SW', 'SW/Farbe', 'Nur Farbe', 'Nur SW'],
      sideOptions: ['1-seitig/2-seitig', '2-seitig/1-seitig', 'Nur 1-seitig', 'Nur 2-seitig']
    }
  },
  computed: {
    ...mapState('auth', [
      'user'
    ]),
    allowCreateGroup () {
      return this.user.allowManageGroup || ['poweruser'].includes(this.user.role)
    },
    orderProviderOptions () {
      if (!this.selectedGroup) {
        return []
      }
      return this.orderProviders
        .filter(provider => !this.selectedGroup.users?.includes(provider._id))
        .sort((a, b) => a.firstName > b.firstName ? 1 : -1)
    },
    supervisorOptions () {
      if (!this.selectedGroup) {
        return []
      }
      return this.orderProviders
        .filter(provider => !this.selectedGroup.supervisors?.includes(provider._id))
        .sort((a, b) => a.firstName > b.firstName ? 1 : -1)
    },
    orderProviderIdToDoc () {
      const hash = {}
      for(const provider of this.orderProviders) {
        hash[provider._id] = provider
      }
      return hash
    },
    sortedGroups () {
      return [...this.groups.data.docs].sort((a, b) => a.isDefault ? -1 : ((a.name > b.name ? 1 : 0)))
    }
  },
  mounted () {
    this.fetchOrderProviders()
  },
  methods: {
    refreshSelectedGroup () {
      this.selectedGroup = this.groups.data.docs.find(({ _id }) => _id === this.selectedGroup._id)
    },
    handleAddUserToGroup () {
      const newUsers = [...new Set([...this.selectedGroup.users, this.newUserId])]
      const payload = {
        groupId: this.selectedGroup._id,
        body: {
          users: newUsers
        }
      }

      this.isAddingUserToGroup = true
      this.$store.dispatch('group/updateGroup', payload)
        .then((data) => {
          this.$store.commit('group/UPDATE_GROUP', data)
          this.refreshSelectedGroup()
          this.$notify({type: 'success', message: 'Die Gruppe Benutzer wurde aktualisiert'})
          this.newUserId = null;
        })
        .catch((error) => {
          this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Fehler beim Versuch, einen Gruppenbenutzer zu aktualisieren' })
        })
        .finally(() => {
          this.isAddingUserToGroup = false
        })
    },
    handleAddSupervisorToGroup () {
      const newUsers = [...new Set([...(this.selectedGroup.supervisors || []), this.newSupervisorId])]
      const payload = {
        groupId: this.selectedGroup._id,
        body: {
          supervisors: newUsers
        }
      }

      this.isAddingSupervisorToGroup = true
      this.$store.dispatch('group/updateGroup', payload)
        .then((data) => {
          this.$store.commit('group/UPDATE_GROUP', data)
          this.refreshSelectedGroup()
          this.$notify({type: 'success', message: 'Die Gruppe Supervisors wurde aktualisiert'})
          this.newSupervisorId = null;
        })
        .catch((error) => {
          this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Fehler beim Versuch, einen Gruppe Supervisor zu aktualisieren' })
        })
        .finally(() => {
          this.isAddingSupervisorToGroup = false
        })
    },
    handleRemoveUserFromGroup (removeUserId) {
      this.$confirm(
        {
          message: `Möchten Sie diesen Benutzer aus der Gruppe entfernen?`,
          button: {
            no: 'Nein',
            yes: 'Ja'
          },
          callback: confirm => {
            if (confirm) {
              const newUsers = this.selectedGroup.users.filter(userId => userId !== removeUserId)
              const payload = {
                groupId: this.selectedGroup._id,
                body: {
                  users: newUsers
                }
              }

              this.isRemovingUserFromGroup = { ...this.isRemovingUserFromGroup, [removeUserId]: true}
              this.$store.dispatch('group/updateGroup', payload)
                .then((data) => {
                  this.$store.commit('group/UPDATE_GROUP', data)
                  this.refreshSelectedGroup()
                  this.$notify({type: 'success', message: 'Die Gruppe Benutzer wurde aktualisiert'})
                })
                .catch((error) => {
                  this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Fehler beim Versuch, einen Gruppenbenutzer zu aktualisieren' })
                })
                .finally(() => {
                  delete this.isRemovingUserFromGroup[removeUserId];
                })
            }
          }
        }
      )
    },
    handleRemoveSupervisorFromGroup (removeUserId) {
      this.$confirm(
        {
          message: `Möchten Sie diesen Supervisor aus der Gruppe entfernen?`,
          button: {
            no: 'Nein',
            yes: 'Ja'
          },
          callback: confirm => {
            if (confirm) {
              const newUsers = this.selectedGroup.supervisors.filter(userId => userId !== removeUserId)
              const payload = {
                groupId: this.selectedGroup._id,
                body: {
                  supervisors: newUsers
                }
              }

              this.isRemovingSupervisorFromGroup = { ...this.isRemovingSupervisorFromGroup, [removeUserId]: true}
              this.$store.dispatch('group/updateGroup', payload)
                .then((data) => {
                  this.$store.commit('group/UPDATE_GROUP', data)
                  this.refreshSelectedGroup()
                  this.$notify({type: 'success', message: 'Die Gruppe Supervisor wurde aktualisiert'})
                })
                .catch((error) => {
                  this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Fehler beim Versuch, einen GruppenSupervisor zu aktualisieren' })
                })
                .finally(() => {
                  delete this.isRemovingSupervisorFromGroup[removeUserId];
                })
            }
          }
        }
      )
    },
    fetchOrderProviders () {
      this.$store.dispatch('group/fetchOrderProviders')
        .then((providers) => {
          this.orderProviders = providers
        })
    },
    getUserPrintSettings (group) {
      return group.userPrintSettings?.[this.user._id]
    },
    setGroupForm (group = null) {
      if (group) {
        let printSettings = {
          color: group.printSettings?.color || null,
          side: group.printSettings?.side || null
        }

        if(group.isDefault) {
          const userPrintSettings = this.getUserPrintSettings(group)
          if(userPrintSettings) {
            printSettings = userPrintSettings
          }
        }

        this.model = {
          name: group.name,
          location: group.location,
          printSettings,
          isDefault: group.isDefault || false
        }
      } else {
        this.model = {
          name: null,
          location: null,
          printSettings: {
            color: null,
            side: null
          },
          isDefault: false
        }
      }
    },
    handleCreateGroup () {
      this.setGroupForm ()
      this.errMsg = null
      this.groupModal.isEdit = false
      this.groupModal.show = true
    },
    handleEdit (row) {
      this.selectedGroup = row
      this.setGroupForm (row)
      this.errMsg = null
      this.groupModal.isEdit = true
      this.groupModal.show = true
    },
    handleEditUser (row) {
      this.selectedGroup = row
      this.setGroupForm (row)
      this.errMsg = null
      this.userModal.show = true
    },
    handleDelete (row) {
      this.$confirm(
        {
          message: `Möchten Sie die Gruppe löschen?`,
          button: {
            no: 'Nein',
            yes: 'Ja'
          },
          callback: confirm => {
            if (confirm) {
              this.$store.dispatch('group/deleteGroup', row._id)
                .then(() => {
                  this.$notify({type: 'success', message: 'Gruppe gelöscht'})
                })
                .catch(({error}) => {
                  this.$notify({type: 'danger', message: error.http_message})
                })
            }
          }
        }
      )
    },
    handleSubmit () {
      this.errMsg = null

      if (this.groupModal.isEdit) {
        // Editing group
        if (!this.model.name || !this.model.printSettings.color || !this.model.printSettings.side) {
          this.errMsg = 'Please fill in all required fields'
          return
        }

        // Submit edit
        const payload = {
          groupId: this.selectedGroup._id,
          body: {
            name: this.model.name,
            location: this.model.location,
            printSettings: {
              color: this.model.printSettings.color,
              side: this.model.printSettings.side
            }
          }
        }

        this.isSubmitting = true
        this.$store.dispatch('group/updateGroup', payload)
          .then((data) => {
            this.$store.commit('group/UPDATE_GROUP', data)
            this.$notify({type: 'success', message: 'Die Gruppendaten wurden aktualisiert'})
            this.groupModal.show = false
            this.selectedGroup = null
            this.setGroupForm()
          })
          .catch((error) => {
            this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Fehler beim Versuch, eine Gruppe zu aktualisieren' })
          })
          .finally(() => {
            this.isSubmitting = false
          })
      }
      else {
        // Creating new group
        if (!this.model.name || !this.model.printSettings.color || !this.model.printSettings.side) {
          this.errMsg = 'Please fill in all required fields'
          return
        }

        // Submit create
        const payload = {
          name: this.model.name,
          location: this.model.location,
          printSettings: {
            color: this.model.printSettings.color,
            side: this.model.printSettings.side
          }
        }

        this.isSubmitting = true
        this.$store.dispatch('group/createGroup', payload)
          .then(() => {
            this.$notify({type: 'success', message: 'Neue Gruppe wurde erstellt'})
            this.setGroupForm()
            this.groupModal.show = false
          })
          .catch((error) => {
            this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Fehler beim Versuch, eine Gruppe zu erstellen' })
          })
          .finally(() => {
            this.isSubmitting = false
          })
      }
    }
  }
}
</script>

<style>
</style>
