<template>
    <div>
        <base-header type="gradient-hyb-cyan" class="pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <groups-table
                      :groups="groups"
                      @paginate="handlePaginate"
                    ></groups-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GroupsTable from './Tables/GroupsTable'
import { mapState } from 'vuex'

export default {
  name: 'GroupTable',
  components: {
    GroupsTable
  },
  computed: {
    ...mapState('group', [
      'groups'
    ])
  },
  mounted () {
    this.fetchGroups()
  },
  methods: {
    fetchGroups () {
      this.$store.dispatch('group/fetchGroups')
    },
    handlePaginate (pageNum) {
      this.$store.dispatch('group/setPageNum', pageNum)
      this.$store.dispatch('group/fetchGroups')
    }
  }
};
</script>

<style></style>
