<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Briefe Diesen Monat" type="gradient-red" :sub-title="getSubtitle('briefeDiesenMonat')" icon="ni ni-active-40"
            class="mb-4 mb-xl-0" />
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Gedruckte Seiten" type="gradient-orange" :sub-title="getSubtitle('gedruckteSeiten')" icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0" />
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Benutzer" type="gradient-green" :sub-title="getSubtitle('benutzer')" icon="ni ni-money-coins"
            class="mb-4 mb-xl-0" />
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Performance" type="gradient-info" :sub-title="getSubtitle('performance')" icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0" subtitle-classes="text-green" />
        </div>
      </div> -->
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-light text-uppercase ls-1 mb-1">Überblick</h6>
                <h5 class="h3 text-white mb-0">Briefe</h5>
              </div>
            </div>
            <line-chart :height="350" ref="bigChart" :chart-data="leftChart.chartData"
              :extra-options="leftChart.extraOptions">
            </line-chart>
          </card>
        </div>

        <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
                <h5 class="h3 mb-0">abgerechnete Aufträge</h5>
              </div>
            </div>
            <bar-chart :height="350" ref="barChart" :chart-data="rightChart.chartData">
            </bar-chart>
          </card>
        </div>
      </div>
      <!-- End charts-->

      <!--Tables-->
      <div class="row my-5">
        <div class="col-xl-12">
          <!-- <base-input class="input-group-alternative mb-3" placeholder="Search" addon-left-icon="fa fa-search"
            v-model="searchTerm">
          </base-input> -->
        </div>
        <div class="col-xl-12">
          <billing-table
            title=""
            :orders="orders"
            @paginate="handlePaginate"
          ></billing-table>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapState } from 'vuex'
import moment from 'moment'

// Charts
import * as chartConfigs from '@/components/Charts/config';
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';

// Tables
import BillingTable from './Tables/BillingTable';

export default {
  components: {
    LineChart,
    BarChart,
    BillingTable
  },
  data() {
    return {
      searchTerm: null,
      leftChart: {
        allData: [],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      rightChart: {
        chartData: {
          labels: [],
          datasets: []
        }
      }
    };
  },
  computed: {
    ...mapState('order', [
      'orders',
      'analytics'
    ]),
    orderQuery () {
      const params = {
        query: {
          status: 'printed'
        }
      }
      return params
    }
  },
  mounted() {
    this.fetchOrderAnalytics()
      .then(() => {
        this.initCharts(0);
      })
    this.resetOrders()
    this.fetchOrders()
  },
  methods: {
    initCharts(index) {
      // Init left chart
      this.leftChart.chartData = {
        datasets: [
          {
            label: 'Printed',
            data: this.analytics.graphUberblickBriefe.map(d => d.count)
          }
        ],
        labels: this.analytics.graphUberblickBriefe.map(d => moment(d.date).format('DD.MM'))
      }
      this.leftChart.activeIndex = index

      // Init right chart
      this.rightChart.chartData = {
        datasets: [{
          label: 'Billed',
          data: this.analytics.graphGedruckteSeiten.map(d => d.count)
        }],
        labels: this.analytics.graphGedruckteSeiten.map(d => moment(d.date).format('DD.MM'))
      }
    },
    getSubtitle (key) {
      if (this.analytics) {
        switch (key) {
          case 'briefeDiesenMonat':
          case 'gedruckteSeiten':
          case 'benutzer':
            return this.analytics[key] + ''
            break
          case 'performance':
            return `${this.analytics[key] * 100}%`
            break
        }
        
      }
      return '...'
    },
    fetchOrders () {
      this.$store.dispatch('order/fetchOrders', this.orderQuery)
    },
    fetchOrderAnalytics () {
      return this.$store.dispatch('order/fetchOrderAnalytics')
    },
    resetOrders () {
      this.$store.dispatch('order/resetOrders')
    },
    handlePaginate (pageNum) {
      this.$store.dispatch('order/setPageNum', pageNum)
      this.$store.dispatch('order/fetchOrders', this.orderQuery)
    }
  }
};
</script>
<style></style>
