<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <div class="row align-items-center ml-2">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
              Adressen
            </h3>
            <base-input class="col-6 input-group-alternative mb-0 ml-4" placeholder="Straße und Nr." addon-left-icon="fa fa-search"
              @input="debounceSearch" :value="searchTerm">
            </base-input>
          </div>
        </div>
        <div class="col text-right">
          <base-button type="primary" size="sm" class="col-auto" @click="handleImport">Import CSV</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table v-if="addresses.data" class="table align-items-center table-flush" :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'" tbody-classes="list" :data="addresses.data.docs">
        <template slot="columns">
          <th>Country</th>
          <th>Area</th>
          <th>ZG</th>
          <th>Postcode</th>
          <th>City</th>
          <th>Street</th>
          <th>Street Nr From</th>
          <th>Street Nr To</th>
          <th>Depot</th>
          <th>Upoc</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.countryId}}</span>
              </div>
            </div>
          </th>
          <td>{{ row.area }}</td>
          <td>{{ row.zg }}</td>
          <td>{{ row.postcode }}</td>
          <td>{{ row.city }}</td>
          <td>{{ row.street }}</td>
          <td>{{ row.streetNrFrom }}</td>
          <td>{{ row.streetNrTo }}</td>
          <td>{{ row.depot }}</td>
          <td>{{ row.upoc }}</td>
        </template>
      </base-table>
    </div>
    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination v-if="addresses.data" :pageCount="addresses.data.totalPages" :value="addresses.data.page" @input="(val) => $emit('paginate', val)"></base-pagination>
    </div>
    <modal :show="modal.import">
      <card shadow type="secondary">
          <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                  <div class="col-8">
                      <h3 class="mb-0">
                        Import addresses CSV
                      </h3>
                  </div>
                  <div class="col-4 text-right">
                    <button type="button"
                            class="close"
                            @click="modal.import = false"
                            data-dismiss="modal"
                            aria-label="Close">
                      <span>×</span>
                    </button>
                  </div>
              </div>
          </div>
          <template>
              <form ref="userForm" @submit.prevent>
                  <div class="">
                      <div class="row">
                          <div class="col-lg-12 mb-4">
                            <div class="text-center">
                              <p v-if="file">
                                {{ file.name }}
                              </p>
                              <base-button
                                v-if="file"
                                type="button"
                                class="btn btn-danger"
                                :disabled="isImporting"
                                @click="removeFile"
                              >
                                <span>
                                  <i class="fa fa-times" />
                                  Remove
                                </span>
                              </base-button>
                              <base-button type="button" class="btn btn-primary" :disabled="isImporting" @click="$refs.csvInput.click()">
                                <label v-if="!file" class="mb-0 cursor-pointer"
                                  >Select CSV</label
                                >
                                <label v-else class="mb-0">Change</label>
                              </base-button>
                              <base-button v-if="file" type="primary" :disabled="isImporting" @click="handleSubmit">Submit</base-button>
                              <input
                                id="csvInput"
                                ref="csvInput"
                                accept=".csv"
                                type="file"
                                style="display: none"
                                @change="onSelectFile"
                              />
                            </div>
                          </div>
                      </div>
                      <p v-if="errMsg" class="mb-0 mt-3 text-danger text-right text-sm">{{ errMsg }}</p>
                  </div>
              </form>
          </template>
      </card>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'debounce'

export default {
  name: 'address-table',
  props: {
    type: {
      type: String
    },
    title: String,
    addresses: Object
  },
  data() {
    return {
      searchTerm: null,
      isImporting: false,
      errMsg: null,
      modal: {
        import: false
      },
      file: null,
      model: {
        role: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        password: null,
        confirmPassword: null,
        company: null,
        customerNo: null
      }
    }
  },
  computed: {
    ...mapState('auth', [
      'user'
    ])
  },
  watch: {
    searchTerm (newVal) {
      this.$store.dispatch('address/fetchAddresses', { search: newVal })
    }
  },
  methods: {
    debounceSearch: debounce (function (e) {
        this.searchTerm = e
      }, 400),
    onSelectFile () {
      this.file = event.target.files[0]
    },
    removeFile () {
      this.$refs.csvInput.value = null
      this.file = null
    },
    handleImport () {
      this.modal.import = true
    },
    handleSubmit () {
      this.errMsg = null
      let formData = new FormData()
      formData.append('file', this.file)

      this.isImporting = true
      this.$store.dispatch('address/importCSV', formData)
        .then(() => {
          this.$notify({type: 'success', message: 'New address CSV has been imported'})
          this.modal.import = false
          this.removeFile()
          this.$emit('import')
        })
        .catch((error) => {
          this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Error while trying to import CSV' })
        })
        .finally(() => {
          this.isImporting = false
        })
    }
  }
}
</script>

<style>
</style>
