<template>
    <div>
        <base-header type="gradient-hyb-cyan" class="pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                  <div class="card shadow" style="min-height: 300px">
                    <div class="card-header border-0">
                      <div class="row align-items-center">
                        <div class="col-xl-auto col-12 mb-xl-0 mb-3">
                          <h3 class="mb-0">
                            Eigenschaften
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div class="px-4">
                      <settings-table class="mb-4" />
                    </div>
                    <template v-if="role === 'admin'">
                      <div class="card-header border-0 pb-1">
                        <div class="row align-items-center">
                          <div class="col-xl-auto col-12 mb-xl-0 mb-3">
                            <h3 class="mb-0">
                              Briefstaffel
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div class="px-4">
                        <letter-types-editor class="mb-4" />
                      </div>
                    </template>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import SettingsTable from './Tables/SettingsTable'
import LetterTypesEditor from './Fragments/LetterTypesEditor'

export default {
  name: 'Settings',
  components: {
    SettingsTable, LetterTypesEditor
  },
  computed: {
    ...mapState('auth', [
      'user'
    ]),
    role () {
      return this.user && this.user.role
    }
  }
};
</script>

<style></style>
