<template>
  <div class="table-responsive">
    <div v-for="setting in settings" :key="setting.label" style="max-width: 500px;">
      <div v-if="!setting.roles || (setting.roles && setting.roles.includes(role))" class="row">
        <div class="col text-left">
          <div class="mb-3">
            <p v-if="(!setting.tag || (setting.tag === 'upoc' && upocActivated))" class="font-weight-bold mb-0">{{ setting.label }}</p>
            <p v-if="setting.caption && upocActivated" class="text-sm">{{ setting.caption }}</p>
          </div>
        </div>
        <div class="col-auto text-right">
          <base-switch v-if="setting.type === 'toggle'" class="" :value="setting.value" @input="val => handleInput(val, setting)" />
          <settings-input v-else-if="setting.type === 'input' && (!setting.tag || (setting.tag === 'upoc' && upocActivated))" :setting="setting" @update="handleUpdate" />
        </div>
      </div>
    </div>
    <div v-if="upocActivated" class="ml-0">
      <base-button type="button" :disabled="isImporting" class="btn btn-primary" @click="$refs.upocFileInput.click()">
        <label class="mb-0 cursor-pointer">UPOCS hochladen</label>
      </base-button>
      <input
        id="upocFileInput"
        ref="upocFileInput"
        accept=".csv"
        type="file"
        style="display: none"
        @change="onSelectFile"
      />
    </div>
    <div v-if="role === 'admin'" class="ml-0 mt-2 d-flex align-items-center">
      <base-button type="button" :disabled="isImportingEinschreiben" class="btn btn-primary" @click="$refs.einschreibenFileInput.click()">
        <label class="mb-0 cursor-pointer">Einschreiben pdf hochladen</label>
      </base-button>
      <input
        id="einschreibenFileInput"
        ref="einschreibenFileInput"
        accept=".pdf"
        type="file"
        style="display: none"
        @change="onSelectEinschreibenFile"
      />
      <a href="#" @click="downloadBrief('Einschreiben')">{{ einschreibenFilename }}</a>
    </div>
    <div v-if="role === 'admin'" class="ml-0 mt-2 d-flex align-items-center">
      <base-button type="button" :disabled="isImportingPZA" class="btn btn-primary" @click="$refs.pzaFileInput.click()">
        <label class="mb-0 cursor-pointer">PZA pdf hochladen</label>
      </base-button>
      <input
        id="pzaFileInput"
        ref="pzaFileInput"
        accept=".pdf"
        type="file"
        style="display: none"
        @change="onSelectPZAFile"
      />
      <a href="#" @click="downloadBrief('PZA')">{{ pzaFilename }}</a>
    </div>
    <div v-if="role === 'admin'" class="ml-0 mt-2 d-flex align-items-center">
      <base-button type="button" :disabled="isImportingPZAPrintHouse" class="btn btn-primary" @click="$refs.pzaPrintHouseFileInput.click()">
        <label class="mb-0 cursor-pointer">PZA printhouse pdf hochladen</label>
      </base-button>
      <input
        id="pzaPrintHouseFileInput"
        ref="pzaPrintHouseFileInput"
        accept=".pdf"
        type="file"
        style="display: none"
        @change="onSelectPZAPrintHouseFile"
      />
      <a href="#" @click="downloadBrief('PZAPrintHouse')">{{ pzaPrintHouseFilename }}</a>
    </div>
    <div v-if="role === 'admin'" class="ml-0 mt-2 d-flex align-items-center">
      <base-button type="button" :disabled="isImportingEnvelopeBig" class="btn btn-primary" @click="$refs.envelopeBigFileInput.click()">
        <label class="mb-0 cursor-pointer">Envelope big pdf hochladen</label>
      </base-button>
      <input
        id="envelopeBigFileInput"
        ref="envelopeBigFileInput"
        accept=".pdf"
        type="file"
        style="display: none"
        @change="onSelectEnvelopeBigFile"
      />
      <a href="#" @click="downloadBrief('EnvelopeBig')">{{ envelopeBigFilename }}</a>
    </div>
    <div v-if="role === 'admin'" class="ml-0 mt-2 d-flex align-items-center">
      <base-button type="button" :disabled="isImportingEnvelopeSmall" class="btn btn-primary" @click="$refs.envelopeSmallFileInput.click()">
        <label class="mb-0 cursor-pointer">Envelope small pdf hochladen</label>
      </base-button>
      <input
        id="envelopeSmallFileInput"
        ref="envelopeSmallFileInput"
        accept=".pdf"
        type="file"
        style="display: none"
        @change="onSelectEnvelopeSmallFile"
      />
      <a href="#" @click="downloadBrief('EnvelopeSmall')">{{ envelopeSmallFilename }}</a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SettingsInput from "../../components/SettingsInput.vue"
import Papa from 'papaparse'

export default {
  name: 'settings-table',
  components: { SettingsInput },
  props: {
    type: String,
    title: String
  },
  data() {
    return {
      errMsg: null,
      settings: [
        {
          type: 'toggle',
          label: 'Aufträge automatisch freigeben',
          value: null
        },
        {
          type: 'toggle',
          label: 'Individuelle Freigabezeitpunkte festlegen',
          value: null,
          roles: ['poweruser']
        },
        {
          type: 'toggle',
          label: 'Word/Excel umwandeln',
          caption: null,
          value: null,
          roles: ['admin']
        },
        {
          type: 'input',
          label: 'Druckschluss',
          caption: null,
          value: null,
          roles: ['admin']
        },
        {
          type: 'toggle',
          label: 'UPOC aktivieren',
          caption: null,
          value: null,
          roles: ['admin']
        },
        {
          type: 'input',
          label: 'Mailaddresse für Warnungen',
          value: null,
          tag: 'upoc',
          roles: ['admin']
        },
        {
          type: 'input',
          label: 'Warnen ab UPOCanzahl',
          value: null,
          tag: 'upoc',
          roles: ['admin']
        }
      ],
      user: null,
      file: null,
      einschreibenFile: null,
      pzaFile: null,
      pzaPrintHouseFile: null,
      envelopeBigFile: null,
      envelopeSmallFile: null,
      isImporting: false,
      isImportingEinschreiben: false,
      isImportingPZA: false,
      isImportingPZAPrintHouse: false,
      isImportingEnvelopeBig: false,
      isImportingEnvelopeSmall: false,
      upocCount: null
    }
  },
  computed: {
    ...mapState({
      userId: state => state.auth.user._id,
      role: state => state.auth.user.role
    }),
    upocActivated () {
      const upocActivatedSetting = this.settings.find(s => s.label === 'UPOC aktivieren')
      return !!upocActivatedSetting?.value
    },
    einschreibenFilename () {
      return this.user?.briefPaths?.['Einschreiben']?.replace(/^.*[\\/]/, '')
    },
    pzaFilename () {
      return this.user?.briefPaths?.['PZA']?.replace(/^.*[\\/]/, '')
    },
    pzaPrintHouseFilename () {
      return this.user?.briefPaths?.['PZAPrintHouse']?.replace(/^.*[\\/]/, '')
    },
    envelopeBigFilename () {
      return this.user?.briefPaths?.['EnvelopeBig']?.replace(/^.*[\\/]/, '')
    },
    envelopeSmallFilename () {
      return this.user?.briefPaths?.['EnvelopeSmall']?.replace(/^.*[\\/]/, '')
    }
  },
  watch: {
    upocCount (val) {
      this.settings[4].caption = `${val} CODES verbleiben`
    }
  },
  mounted () {
    this.fetchUser()
    if (this.role === 'admin') {
      this.fetchUpocCount()
    }
  },
  methods: {
    onSelectFile () {
      this.file = event.target.files[0]
      this.errMsg = null

      Papa.parse(this.file, {
        delimitersToGuess: [',', ';'],
        complete: (results) => {
          // Stop upload if there are errors while parsing the csv file
          if (results.errors.length) {
            this.$notify({type: 'danger', message: `CSV format is not valid. ${results.errors[0]?.message}`})
            return
          }

          // Check if the csv has at least 2 columns for the upoc code and chars
          const firstRow = results.data?.[0]
          if (!firstRow || !firstRow[0] || !firstRow[1]) {
            this.$notify({type: 'danger', message: `CSV format is not valid. Appropriate columns are missing or badly formatted.`})
            return
          }

          let formData = new FormData()
          formData.append('file', this.file)
          
          this.isImporting = true
          this.$store.dispatch('upocs/importCSV', formData)
            .then((data) => {
              this.$notify({type: 'success', message: 'New UPOCS CSV has been imported'})
              this.upocCount = this.upocCount + data.length
              this.removeFile()
            })
            .catch((error) => {
              this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Error while trying to import CSV' })
            })
            .finally(() => {
              this.isImporting = false
            })
        }
      })
    },
    onSelectEinschreibenFile () {
      this.einschreibenFile = event.target.files[0]
      this.errMsg = null

      let formData = new FormData()
      formData.append('file', this.einschreibenFile)
      formData.append('briefType', 'Einschreiben')

      this.isImportingEinschreiben = true
      this.$store.dispatch('user/uploadBrief', formData)
        .then(() => {
          this.$notify({type: 'success', message: 'Einschreiben pdf has been uploaded'})
          this.$refs.einschreibenFileInput.value = null
          this.einschreibenFile = null
        })
        .catch((error) => {
          this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Error while trying to upload Einschreiben file' })
        })
        .finally(() => {
          this.isImportingEinschreiben = false
        })
    },
    onSelectPZAFile () {
      this.pzaFile = event.target.files[0]
      this.errMsg = null

      let formData = new FormData()
      formData.append('file', this.pzaFile)
      formData.append('briefType', 'PZA')

      this.isImportingPZA = true
      this.$store.dispatch('user/uploadBrief', formData)
        .then(() => {
          this.$notify({type: 'success', message: 'PZA pdf has been uploaded'})
          this.$refs.pzaFileInput.value = null
          this.pzaFile = null
        })
        .catch((error) => {
          this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Error while trying to upload PZA file' })
        })
        .finally(() => {
          this.isImportingPZA = false
        })
    },
    onSelectPZAPrintHouseFile () {
      this.pzaPrintHouseFile = event.target.files[0]
      this.errMsg = null

      let formData = new FormData()
      formData.append('file', this.pzaPrintHouseFile)
      formData.append('briefType', 'PZAPrintHouse')

      this.isImportingPZAPrintHouse = true
      this.$store.dispatch('user/uploadBrief', formData)
        .then(() => {
          this.$notify({type: 'success', message: 'PZA for printhouse has been uploaded'})
          this.$refs.pzaPrintHouseFileInput.value = null
          this.pzaPrintHouseFile = null
        })
        .catch((error) => {
          this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Error while trying to upload PZA printhouse file' })
        })
        .finally(() => {
          this.isImportingPZAPrintHouse = false
        })
    },
    onSelectEnvelopeBigFile () {
      this.envelopeBigFile = event.target.files[0]
      this.errMsg = null

      let formData = new FormData()
      formData.append('file', this.envelopeBigFile)
      formData.append('briefType', 'EnvelopeBig')

      this.isImportingEnvelopeBig = true
      this.$store.dispatch('user/uploadBrief', formData)
        .then(() => {
          this.$notify({type: 'success', message: 'Envelope big pdf has been uploaded'})
          this.$refs.envelopeBigFileInput.value = null
          this.envelopeBigFile = null
        })
        .catch((error) => {
          this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Error while trying to upload Envelope Big file' })
        })
        .finally(() => {
          this.isImportingEnvelopeBig = false
        })
    },
    onSelectEnvelopeSmallFile () {
      this.envelopeSmallFile = event.target.files[0]
      this.errMsg = null

      let formData = new FormData()
      formData.append('file', this.envelopeSmallFile)
      formData.append('briefType', 'EnvelopeSmall')

      this.isImportingEnvelopeSmall = true
      this.$store.dispatch('user/uploadBrief', formData)
        .then(() => {
          this.$notify({type: 'success', message: 'Envelope small pdf has been uploaded'})
          this.$refs.envelopeSmallFileInput.value = null
          this.envelopeSmallFile = null
        })
        .catch((error) => {
          this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Error while trying to upload Envelope Small file' })
        })
        .finally(() => {
          this.isImportingEnvelopeSmall = false
        })
    },
    downloadBrief (briefType) {
      this.$store.dispatch('user/getBrief', { briefType })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
          const link = document.createElement('a')
          link.href = url

          const contentDisposition = response.headers['content-disposition']
          let fileName = `${briefType}.pdf`
          if (contentDisposition) {
            fileName = contentDisposition.split('filename=')[1].split(';')[0]
          }
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => {
          this.$notify({type: 'danger', message: 'Pdf is not available'})
        })
    },
    removeFile () {
      this.$refs.upocFileInput.value = null
      this.file = null
    },
    handleUpdate (setting) {
      switch(setting.label) {
        case 'Mailaddresse für Warnungen':
          this.updateUser({ upocSettings: { ...this.user.upocSettings, warningEmail: setting.value } })
          break
        case 'Warnen ab UPOCanzahl':
          this.updateUser({ upocSettings: { ...this.user.upocSettings, warningLimit: setting.value } })
          break
        case 'Druckschluss':
          this.updateUser({ printCloseTime: setting.value })
          break
      }
    },
    handleInput (val, setting) {
      if (typeof(val) === 'boolean') {
        setting.value = val
        switch (setting.label) {
          case 'Aufträge automatisch freigeben':
            this.updateUser({ autogrant: val })
            break
          case 'Individuelle Freigabezeitpunkte festlegen':
            this.updateUser({ autograntIndividual: val })
            break
          case 'UPOC aktivieren':
            this.updateUser({ upocSettings: { ...this.user.upocSettings, activated: val } })
            break
          case 'Word/Excel umwandeln':
            this.updateUser({ convertEnabled: val })
            break
        }
      }
    },
    populateSettings () {
      this.settings[0].value = this.user.autogrant
      this.settings[1].value = this.user.autograntIndividual
      this.settings[2].value = this.user.convertEnabled
      this.settings[3].value = this.user.printCloseTime
      this.settings[4].value = this.user.upocSettings?.activated
      this.settings[5].value = this.user.upocSettings?.warningEmail
      this.settings[6].value = this.user.upocSettings?.warningLimit
    },
    fetchUser () {
      this.$store.dispatch('user/fetchUser', this.userId)
        .then((data) => {
          this.user = data
          this.populateSettings()
        })
        .catch(() => {
          this.$notify({type: 'danger', message: 'Cannot fetch user information'})
        })
    },
    fetchUpocCount () {
      this.$store.dispatch('upocs/fetchUpocs', { query: { userId: this.userId, claimedAt: null }})
        .then(({ data }) => {
          const { totalDocs } = data
          this.upocCount = totalDocs
        })
        .catch(() => {
          this.$notify({type: 'danger', message: 'Cannot fetch user upoc'})
        })
    },
    updateUser (body) {
      const payload = {
        userId: this.userId,
        body
      }
      this.$store.dispatch('user/updateUser', payload)
        .then((data) => {
          this.user = data
          this.$notify({type: 'success', message: 'User settings saved'})
        })
        .catch(({error}) => {
          this.$notify({type: 'danger', message: error.http_message})
        })
    }
  }
}
</script>

<style>
</style>
