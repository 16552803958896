<template>
    <div>
        <base-header type="gradient-hyb-cyan" class="pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <users-table
                      :users="users"
                      @paginate="handlePaginate"
                    ></users-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UsersTable from './Tables/UsersTable'
import { mapState } from 'vuex'

export default {
  name: 'User',
  components: {
    UsersTable
  },
  computed: {
    ...mapState('user', [
      'users'
    ])
  },
  mounted () {
    this.fetchUsers()
  },
  methods: {
    fetchUsers () {
      this.$store.dispatch('user/fetchUsers')
    },
    handlePaginate (pageNum) {
      this.$store.dispatch('user/setPageNum', pageNum)
      this.$store.dispatch('user/fetchUsers')
    }
  }
};
</script>

<style></style>
