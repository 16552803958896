<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table v-if="orders" class="table align-items-center table-flush" :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'" tbody-classes="list" :data="orders.docs">
        <template slot="columns">
          <th>Bearbeiter</th>
          <th>Ordnername</th>
          <th>Datum</th>
          <th>Zeit</th>
          <th>Farbe</th>
          <th>Druckseite</th>
          <th>Seitenzahl</th>
          <th>Aktion</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span v-if="row.userId" class="name mb-0 text-sm">{{row.userId.firstName}} {{row.userId.lastName}}</span>
              </div>
            </div>
          </th>
          <td>{{ row.customName }}</td>
          <td>{{ row.createdAt | formatDate }}</td>
          <td>{{ row.createdAt | formatTime }}</td>
          <td>{{ row.colormode }}</td>
          <td>
            <span v-if="row.duplex">2-seitig</span>
            <span v-else>1-seitig</span>
          </td>
          <td>{{ calculatePageCount(row) }}</td>
          <td>
            <base-button type="success" size="sm" @click="handleEdit(row)">bearbeiten</base-button>
            <base-button type="danger" size="sm" @click="handleDelete(row)">löschen</base-button>
            <base-button type="warning" size="sm" @click="handleDownload(row)">download</base-button>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'collection-table',
  props: {
    type: {
      type: String
    },
    title: String,
    orders: Object
  },
  data() {
    return {
      selectedOrders: {}
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    calculatePageCount (row) {
      let count = 0
      if (row.document) {
        count += row.document.pageCount
      }
      if (row.attachments && row.attachments.length) {
        count += row.attachments.map(a => a.pageCount).reduce((a, b) => a + b, 0)
      }
      return count
    },
    handleDownload (row) {
      row.isDownloading = true

      this.$store.dispatch('order/fetchOrderBundle', row._id)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
          const link = document.createElement('a')
          link.href = url

          const contentDisposition = response.headers['content-disposition']
          let fileName = `${row._id}.pdf`
          if (contentDisposition) {
            fileName = contentDisposition.split('filename=')[1].split(';')[0]
          }
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => {
          this.$notify({type: 'danger', message: 'Pdf is not available'})
        })
        .finally(() => {
          row.isDownloading = false
        })
    },
    handleDelete (row) {
      this.$confirm(
        {
          message: `Möchten Sie den Brief löschen?`,
          button: {
            no: 'Nein',
            yes: 'Ja'
          },
          callback: confirm => {
            if (confirm) {
              row.isDeleting = true
              this.$store.dispatch('order/deleteOrder', row._id)
                .then(() => {
                  this.$store.commit('order/DELETE_COLLECTION', row._id)
                  this.$notify({type: 'success', message: 'Collection order deleted'})
                })
                .catch(({error}) => {
                  this.$notify({type: 'danger', message: error.http_message})
                })
                .finally(() => {
                  row.isDeleting = false
                })
            }
          }
        }
      )
    },
    handleEdit (row) {
      row.isGranting = true
      this.$store.dispatch('order/fetchOptionOrder', row._id)
        .then((data) => {
          const optionWindow = window.open('', 'Collection Order', 'width=850,height=850')
          const optionHtml = data.data.replace('%%UI_CLIENT%%', true)
          optionWindow.document.write(optionHtml)
        })
        .catch(({error}) => {
          this.$notify({type: 'danger', message: error.http_message})
        })
        .finally(() => {
          row.isGranting = false
        })
    }
  }
}
</script>

<style>
</style>
