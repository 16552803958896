<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            Benutzer
          </h3>
        </div>
        <div class="col d-flex align-items-center text-right">
          <base-input
            class="input-group-alternative mb-0"
            placeholder="Search"
            addon-left-icon="fa fa-search"
            :value="searchTerm"
            @input="debounceSearch"
          />
          <base-button v-if="allowCreateUser" type="primary" size="sm" class="ml-2" @click="handleCreateUser">Nutzer erstellen</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table v-if="users.data" class="table align-items-center table-flush" :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'" tbody-classes="list" :data="users.data.docs">
        <template slot="columns">
          <th>Benutzer</th>
          <th>Rolle</th>
          <th>Name</th>
          <th>E-Mail</th>
          <th>Telefon</th>
          <th>Optionen</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.username}}</span>
              </div>
            </div>
          </th>
          <td>{{ row.role }}</td>
          <td>{{ row.firstName }} {{ row.lastName }}</td>
          <td>{{ row.email }}</td>
          <td>{{ row.phone }}</td>
          <td>
            <base-button type="success" size="sm" @click="handleEdit(row)" :disabled="row.granted">bearbeiten</base-button>
            <base-button v-if="userNotSelf(row) && notSuperadmin(row)" type="danger" size="sm" @click="handleDelete(row)">löschen</base-button>
          </td>
        </template>
      </base-table>
    </div>
    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination v-if="users.data" :pageCount="users.data.totalPages" :value="users.data.page" @input="(val) => $emit('paginate', val)"></base-pagination>
    </div>
    <modal :show="userModal.show">
      <card shadow type="secondary">
          <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                  <div class="col-8">
                      <h3 class="mb-0">
                        {{ userModal.isEdit ? 'Nutzer Bearbeiten' : 'Neuen Nutzer Erstellen' }}</h3>
                  </div>
                  <div class="col-4 text-right">
                    <button type="button"
                            class="close"
                            @click="userModal.show = false"
                            data-dismiss="modal"
                            aria-label="Close">
                      <span>×</span>
                    </button>
                  </div>
              </div>
          </div>
          <template>
              <form ref="userForm" @submit.prevent>
                  <div class="">
                      <div v-if="!hideRoleOnEdit" class="row">
                          <div class="col-lg-12">
                              <base-input label="Rolle*">
                                <select v-model="model.role" class="form-control" required>
                                  <option v-for="(role, index) in roleOptions" :key="index">{{ getTranslatedRole(role) }}</option>
                                </select>
                              </base-input>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Vorname*"
                                          placeholder="Vorname"
                                          input-classes="form-control-alternative"
                                          v-model="model.firstName"
                              />
                          </div>
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Lastname*"
                                          placeholder="Lastname"
                                          input-classes="form-control-alternative"
                                          v-model="model.lastName"
                              />
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="E-Mail*"
                                          placeholder="E-Mail"
                                          input-classes="form-control-alternative"
                                          v-model="model.email"
                              />
                          </div>
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Telefon*"
                                          placeholder="Telefon"
                                          input-classes="form-control-alternative"
                                          v-model="model.phone"
                              />
                          </div>
                      </div>
                      <div v-if="userModal.isEdit || (!userModal.isEdit && allowManageCustomerNo && model.role === getTranslatedRole('poweruser'))" class="row">
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Firma"
                                          placeholder="Firma"
                                          input-classes="form-control-alternative"
                                          v-model="model.company"
                                          :readonly="!allowManageCustomerNo"
                              />
                          </div>
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Kundennummer"
                                          placeholder="Kundennummer"
                                          input-classes="form-control-alternative"
                                          v-model="model.customerNo"
                                          :readonly="!allowManageCustomerNo"
                              />
                          </div>
                          <div class="col-lg-6" v-if="model.role === getTranslatedRole('poweruser')">
                              <base-input alternative=""
                                          label="Kunden-UPOC"
                                          placeholder="Kunden-UPOC"
                                          input-classes="form-control-alternative"
                                          v-model="model.customerUpoc"
                                          :readonly="!allowManageCustomerNo"
                              />
                          </div>
                      </div>
                      <div v-if="userModal.isEdit" class="row">
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Password"
                                          placeholder="Password"
                                          type="password"
                                          input-classes="form-control-alternative"
                                          v-model="model.password"
                              />
                          </div>
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Reenter Password"
                                          placeholder="Reenter Password"
                                          type="password"
                                          input-classes="form-control-alternative"
                                          v-model="model.confirmPassword"
                              />
                          </div>
                      </div>
                      <div v-if="model.role === getTranslatedRole('poweruser')" class="row mb-4">
                        <div v-if="allowManageGrant" class="col-lg-6 mb-1">
                          <label class="form-control-label">Kann Unternutzer erstellen?</label>
                          <base-checkbox v-model="model.allowPostUser" />
                        </div>
                        <div v-if="allowManageBooked" class="col-lg-6 mb-1">
                          <label class="form-control-label">Sammelmappen<br>gebucht?</label>
                          <base-checkbox v-model="model.collectionBooked" />
                        </div>
                        <!-- <div class="col-lg-6 invisible">
                          <label class="form-control-label">Auto Grant?</label>
                          <base-checkbox v-model="model.autogrant" />
                        </div> -->
                        <div class="col-lg-6 mb-1">
                          <label class="form-control-label">Kann Einschreiben erstellen?</label>
                          <base-checkbox v-model="model.allowEinschreiben" />
                        </div>
                        <div class="col-lg-6 mb-1">
                          <label class="form-control-label">Kann PZA erstellen?</label>
                          <base-checkbox v-model="model.allowPZA" />
                        </div>
                        <div class="col-lg-6 mb-1">
                          <label class="form-control-label">Kann Gruppen erstellen?</label>
                          <base-checkbox v-model="model.allowManageGroup" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col text-right">
                          <base-button type="light" @click="userModal.show = false">Abbrechen</base-button>
                          <base-button type="primary" :disabled="isSubmitting" @click="handleSubmit">Bestätigen</base-button>
                        </div>
                      </div>
                      <p v-if="errMsg" class="mb-0 mt-3 text-danger text-right text-sm">{{ errMsg }}</p>
                  </div>
              </form>
          </template>
      </card>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'debounce'

export default {
  name: 'users-table',
  props: {
    type: {
      type: String
    },
    title: String,
    users: Object
  },
  data() {
    return {
      isSubmitting: false,
      errMsg: null,
      selectedUser: null,
      userModal: {
        show: false,
        isEdit: false
      },
      searchTerm: null,
      model: {
        role: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        password: null,
        confirmPassword: null,
        company: null,
        customerNo: null,
        customerUpoc: null,
        autogrant: false,
        allowPostUser: true,
        collectionBooked: false,
        allowEinschreiben: false,
        allowPZA: false,
        allowManageGroup: false
      }
    }
  },
  computed: {
    ...mapState('auth', [
      'user'
    ]),
    roleOptions () {
      let options = []
      switch(this.user.role) {
        case 'superadmin':
          options = ['superadmin', 'admin', 'poweruser', 'controller', 'order-provider']
          break
        case 'admin':
          options = ['poweruser', 'print-house', 'billing']
          break
        case 'poweruser':
          options = ['controller']
          break
        case 'controller':
          options = ['order-provider']
          break
      }
      return options
    },
    allowCreateUser () {
      return this.user.allowPostUser || ['superadmin', 'admin'].includes(this.user.role)
    },
    allowManageCustomerNo () {
      return ['admin'].includes(this.user.role)
    },
    allowManageGrant () {
      return ['superadmin', 'admin'].includes(this.user.role)
    },
    allowManageBooked () {
      return ['superadmin', 'admin'].includes(this.user.role)
    },
    hideRoleOnEdit () {
      return this.userModal.isEdit && ['poweruser', 'controller', 'order-provider'].includes(this.user.role)
    }
  },
  watch: {
    searchTerm (newVal) {
      this.$store.dispatch('user/fetchUsers', { search: newVal })
    }
  },
  methods: {
    debounceSearch: debounce (function (e) {
      this.searchTerm = e
    }, 400),
    userNotSelf (row) {
      return (row._id !== this.user._id)
    },
    notSuperadmin (row) {
      return row.email !== 'superadmin@garloff-media.de'
    },
    setUserForm (user = null) {
      if (user) {
        this.model = {
          role: this.getTranslatedRole(user.role),
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
          company: user.company,
          customerNo: user.customerNo,
          customerUpoc: user.customerUpoc,
          password: null,
          confirmPassword: null,
          allowPostUser: user.allowPostUser,
          autogrant: user.autogrant,
          collectionBooked: user.collectionBooked,
          allowEinschreiben: !!user.allowEinschreiben,
          allowPZA: !!user.allowPZA,
          allowManageGroup: !!user.allowManageGroup
        }
      } else {
        this.model = {
          role: null,
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
          company: null,
          customerNo: null,
          customerUpoc: null,
          password: null,
          confirmPassword: null,
          allowPostUser: true,
          autogrant: false,
          collectionBooked: false,
          allowEinschreiben: false,
          allowPZA: false,
          allowManageGroup: false
        }
      }
    },
    handleCreateUser () {
      this.setUserForm ()
      this.errMsg = null
      this.userModal.isEdit = false
      this.userModal.show = true
    },
    handleEdit (row) {
      this.selectedUser = row
      this.setUserForm (row)
      this.errMsg = null
      this.userModal.isEdit = true
      this.userModal.show = true
    },
    handleDelete (row) {
      this.$confirm(
        {
          message: `Möchten Sie den Benutzer löschen?`,
          button: {
            no: 'Nein',
            yes: 'Ja'
          },
          callback: confirm => {
            if (confirm) {
              this.$store.dispatch('user/deleteUser', row._id)
                .then(() => {
                  this.$notify({type: 'success', message: 'Benutzer gelöscht'})
                })
                .catch(({error}) => {
                  this.$notify({type: 'danger', message: error.http_message})
                })
            }
          }
        }
      )
    },
    handleSubmit () {
      this.errMsg = null

      if (this.userModal.isEdit) {
        // Editing user
        if (!this.model.role || !this.model.firstName || !this.model.lastName || !this.model.email || !this.model.phone) {
          this.errMsg = 'Please fill in all required fields'
          return
        }
        if ((this.model.password && !this.model.confirmPassword) || this.model.password !== this.model.confirmPassword) {
          this.errMsg = 'Password does not match'
          return
        }

        // Submit edit
        const payload = {
          userId: this.selectedUser._id,
          body: {
            role: this.reverseTranslated(this.model.role),
            firstName: this.model.firstName,
            lastName: this.model.lastName,
            email: this.model.email,
            phone: this.model.phone
          }
        }
        if (this.allowManageCustomerNo) {
          payload.body.customerNo = this.model.customerNo
          payload.body.customerUpoc = this.model.customerUpoc
          payload.body.company = this.model.company
        }
        if (this.allowManageGrant) {
          payload.body.allowPostUser = this.model.allowPostUser
          payload.body.autogrant = this.model.autogrant
        }
        if (this.allowManageBooked) {
          payload.body.collectionBooked = this.model.collectionBooked
        }

        if (this.model.password) {
          payload.body.password = this.model.password
        }
        if (this.model.company) {
          payload.body.company = this.model.company
        }
        if (this.model.customerNo) {
          payload.body.customerNo = this.model.customerNo
        }
        if(this.model.role === this.getTranslatedRole('poweruser')) {
          payload.body.allowEinschreiben = this.model.allowEinschreiben
          payload.body.allowPZA = this.model.allowPZA
          payload.body.allowManageGroup = this.model.allowManageGroup
        }

        this.isSubmitting = true
        this.$store.dispatch('user/updateUser', payload)
          .then((data) => {
            this.$store.commit('user/UPDATE_USER', data)
            this.$notify({type: 'success', message: 'User data has been updated'})
            this.userModal.show = false
            this.selectedUser = null
            this.setUserForm()
          })
          .catch((error) => {
            this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Error while trying to update user' })
          })
          .finally(() => {
            this.isSubmitting = false
          })
      }
      else {
        // Creating new user
        if (!this.model.role || !this.model.firstName || !this.model.lastName || !this.model.email || !this.model.phone) {
          this.errMsg = 'Please fill in all required fields'
          return
        }

        // Submit create
        const payload = {
          role: this.reverseTranslated(this.model.role),
          firstName: this.model.firstName,
          lastName: this.model.lastName,
          email: this.model.email,
          phone: this.model.phone,
          company: this.model.company
        }
        if (this.allowManageCustomerNo) {
          payload.customerNo = this.model.customerNo
          payload.customerUpoc = this.model.customerUpoc
          payload.company = this.model.company
        }
        if (this.allowManageGrant) {
          payload.allowPostUser = this.model.allowPostUser
          payload.autogrant = this.model.autogrant
        }
        if(this.model.role === this.getTranslatedRole('poweruser')) {
          payload.allowEinschreiben = this.model.allowEinschreiben
          payload.allowPZA = this.model.allowPZA
          payload.allowManageGroup = this.model.allowManageGroup
        }

        this.isSubmitting = true
        this.$store.dispatch('user/createUser', payload)
          .then(() => {
            this.$notify({type: 'success', message: 'Neuer Benutzer wurde erstellt'})
            this.setUserForm()
            this.userModal.show = false
          })
          .catch((error) => {
            this.$notify({type: 'danger', message: error?.response?.data?.error?.description || 'Error while trying to create user' })
          })
          .finally(() => {
            this.isSubmitting = false
          })
      }
    },
    getTranslatedRole (role) {
      let translated = role
      switch (role) {
        case 'superadmin':
        case 'admin':
          break
        case 'poweruser':
          translated = 'Kunde'
          break
        case 'controller':
          translated = 'Abteilungsleiter'
          break
        case 'order-provider':
          translated = 'Nutzer'
          break
        case 'print-house':
          translated = 'Druckerei'
          break
        case 'billing':
          translated = 'Abrechnung'
          break
      }
      return translated
    },
    reverseTranslated (role) {
      let reverse = role
      switch (role) {
        case 'Kunde':
          reverse = 'poweruser'
          break
        case 'Abteilungsleiter':
          reverse = 'controller'
          break
        case 'Nutzer':
          reverse = 'order-provider'
          break
        case 'Druckerei':
          reverse = 'print-house'
          break
        case 'Abrechnung':
          reverse = 'billing'
          break
      }
      return reverse
    }
  }
}
</script>

<style>
</style>
