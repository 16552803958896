<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col-xl-auto col-12 mb-xl-0 mb-3">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            Zum Druck Freigeben
          </h3>
        </div>
        <div class="col text-xl-right text-left">
          <div v-if="settingsCount" class="flex">
            <base-dropdown v-for="(setting, index) in orderSettings" :key="index" :position="isScreenXl ? 'right' : 'left'">
              <base-button slot="title" type="secondary" class="dropdown-toggle">
                {{ setting.label }}
              </base-button>
              <a v-for="index in 8" :key="index" class="dropdown-item" :class="settingsCount[setting.query.colormode][setting.query.duplex][index].count === 0 ? 'disabled' : 0" href="#" @click="handleDownloadPrint(setting, index)">{{ index }} {{ index > 1 ? 'Seiten' : 'Seite' }}
                <span v-if="settingsCount[setting.query.colormode][setting.query.duplex][index].count">({{ settingsCount[setting.query.colormode][setting.query.duplex][index].count }})</span>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" :class="settingsCount[setting.query.colormode][setting.query.duplex][9].count === 0 ? 'disabled' : 0" @click="handleDownloadPrint(setting, 9)">
                Alle anderen
                <span v-if="settingsCount[setting.query.colormode][setting.query.duplex][9].count">({{ settingsCount[setting.query.colormode][setting.query.duplex][9].count }})</span>
              </a>
            </base-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table v-if="orders.data" class="table align-items-center table-flush" :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'" tbody-classes="list" :data="orders.data.docs">
        <template slot="columns">
          <th>CustomerNr</th>
          <th>Datum</th>
          <th>Zeit</th>
          <th>Farbe</th>
          <th>Druckseite</th>
          <th>Seitenzahl</th>
          <th>Aktion</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm" v-if="row.userId">{{row.userId.customerNo}}</span>
              </div>
            </div>
          </th>
          <td>{{ row.createdAt | formatDate }}</td>
          <td>{{ row.createdAt | formatTime }}</td>
          <td>{{ row.colormode }}</td>
          <td>
            <span v-if="row.duplex">2-seitig</span>
            <span v-else>1-seitig</span>
          </td>
          <td>{{ calculatePageCount(row) }} <span v-if="row.pagesplit && row.pagesplit > 0">(Serienbrief)</span></td>
          <td>
            <base-button type="warning" size="sm" @click="handleDownload(row)">download</base-button>
            <base-button type="success" size="sm" @click="handlePrint(row)" :disabled="isPrinted(row)">
              {{ isPrinted(row) ? 'printed' : 'print' }}
            </base-button>
          </td>
        </template>
      </base-table>
    </div>
    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination v-if="orders.data" :pageCount="orders.data.totalPages" :value="orders.data.page" @input="(val) => $emit('paginate', val)"></base-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

export default {
  name: 'print-table',
  props: {
    type: String,
    title: String,
    orders: Object
  },
  data() {
    return {
      errMsg: null,
      windowWidth: window.innerWidth,
      orderSettings: [
        {
          label: 'SW',
          query: {
            colormode: 'bw',
            duplex: false,
          }
        },
        {
          label: 'SW-duplex',
          query: {
            colormode: 'bw',
            duplex: true,
          }
        },
        {
          label: 'Farbe',
          query: {
            colormode: 'color',
            duplex: false,
          }
        },
        {
          label: 'Farbe-duplex',
          query: {
            colormode: 'color',
            duplex: true,
          }
        }
      ]
    }
  },
  computed: {
    ...mapState('auth', [
      'user'
    ]),
    isScreenXl () {
      return this.windowWidth >= 1200
    },
    settingsCount () {
      let settings = {}
      for (const setting of this.orderSettings) {
        if (!(setting.query.colormode in settings)) {
          settings[setting.query.colormode] = {}
        }
        if (!(setting.query.duplex in settings[setting.query.colormode])) {
          settings[setting.query.colormode][setting.query.duplex] = {}
        }
        for (let i = 1; i < 10; i++) {
          settings[setting.query.colormode][setting.query.duplex][i] = {
            count: 0,
            ids: []
          }
        }
      }

      if (this.orders && this.orders.data) {
        this.orders.data.docs.filter(o => o.status === 'submitted').forEach((order) => {
          if (order.colormode != null && order.duplex != null) {
            let totalPageCount = this.calculatePageCount(order)
            if (totalPageCount > 0) {
              totalPageCount = totalPageCount > 8 ? 9 : totalPageCount

              settings[order.colormode][order.duplex][totalPageCount].count += 1
              settings[order.colormode][order.duplex][totalPageCount].ids.push(order._id)
            }
          }
        })
      }
      return settings
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    isPrinted (row) {
      return row.status === 'printed'
    },
    calculatePageCount (row) {
      let count = 0
      if (row.document) {
        count += row.document.pageCount
      }
      if (row.attachments && row.attachments.length) {
        count += row.attachments.map(a => a.pageCount).filter(a => a).reduce((a, b) => a + b, 0)
      }
      return count
    },
    fetchBundle (id) {
      this.$store.dispatch('order/fetchOrderBundle', id)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
          const link = document.createElement('a')
          link.href = url

          const contentDisposition = response.headers['content-disposition']
          let fileName = `${id}.pdf`
          if (contentDisposition) {
            fileName = contentDisposition.split('filename=')[1].split(';')[0]
          }
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => {
          this.$notify({type: 'danger', message: 'Pdf is not available'})
        })
    },
    updatePrint (id) {
      this.$store.dispatch('order/printOrder', id)
        .then(() => {
          this.$notify({type: 'success', message: 'Order printed'})
        })
        .catch(({error}) => {
          this.$notify({type: 'danger', message: error.http_message})
        })
    },
    handleDownload (row) {
      this.fetchBundle (row._id)
    },
    handlePrint (row) {
      this.$confirm(
        {
          message: `Print this order?`,
          button: {
            no: 'Nein',
            yes: 'Ja'
          },
          callback: confirm => {
            if (confirm) {
              this.updatePrint(row._id)
            }
          }
        }
      )
    },
    handleDownloadPrint (setting, index) {
      if (this.settingsCount) {
        const zip = new JSZip()
        const zipName = `${setting.label}-${index}-${(index > 1 ? 'Seiten' : 'Seite')}-${new Date().getTime().toString()}.zip`

        const ids = this.settingsCount[setting.query.colormode][setting.query.duplex][index].ids
        if (ids && ids.length) {
          // Download all order bundle and zip
          let pdfFetchPromises = []
          for (const id of ids) {
            pdfFetchPromises.push(this.$store.dispatch('order/fetchOrderBundle', id))
          }
          Promise.all(pdfFetchPromises)
            .then((responses) => {
              for (const response of responses) {
                const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
                const contentDisposition = response.headers['content-disposition']

                // Get pdf filename
                let fileName = `${new Date().getTime().toString()}.pdf`
                if (contentDisposition) {
                  fileName = contentDisposition.split('filename=')[1].split(';')[0]
                }
                zip.file(fileName, pdfBlob)
              }
              zip.generateAsync({type:'blob'})
                .then((content) => {
                  saveAs(content, zipName)
                })
            })

           // Print all order
           let printPromises = []
           for (const id of ids) {
             printPromises.push(this.$store.dispatch('order/printOrder', id))
           }
          Promise.all(printPromises)
            .then(() => {
              this.$notify({type: 'success', message: 'All orders have been printed'})
            })
        }
      }
    }
  }
}
</script>

<style>
</style>
